import { userProfileSearch } from "./userProfileSearch"
import { internalNotification } from "./internalNotification"
import { project } from "./project"
import { swProjectApplication } from "./swProjectApplication"
import { swUserProfile } from "./swUserProfile"

export const user = {
  userProfileSearch,
  internalNotification,
  project,
  swProjectApplication,
  swUserProfile,
}
