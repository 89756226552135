import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { SwProjectApplication } from "~/clApi/viewModel/swProjectApplication"

const basePath = "/matching/user/sw_project_application"

export const read = {
  getSwProjectApplicationForUser: ({ projectId }: { projectId: string }) =>
    new ApiClient<SwProjectApplication>().get(
      `${basePath}/show?projectId=${projectId}`
    ),
}
