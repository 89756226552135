/**
 * SW企業アカウントに関する状態管理をしている。
 */
import Vue from "vue"
import { clApi } from "~/clApi"
import { SwCorporationAccountListItemView } from "~/clApi/viewModel/swCorporationAccount"

interface SwCorporationAccountState {
  /**
   * データが取得済みかどうか
   */
  isFresh: boolean
  /**
   * SW企業アカウントのリスト
   */
  list: SwCorporationAccountListItemView[]
}

const makeDefaultState = (): SwCorporationAccountState => ({
  isFresh: false,
  list: [],
})

// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: SwCorporationAccountState = Vue.observable<SwCorporationAccountState>(
  makeDefaultState()
)

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setIsFresh(fresh: boolean) {
    state.isFresh = fresh
  },
  setList(list: SwCorporationAccountListItemView[]) {
    state.list = list
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get isFresh(): boolean {
    return state.isFresh
  },
  isSwCorporationAccount(universalCorporationId?: string): boolean {
    return state.list.some(
      (item) => item.universalCorporationId === universalCorporationId
    )
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  loadList: async () => {
    if (getters.isFresh) {
      return
    }

    try {
      const data = await clApi.main.general.swCorporationAccount.read.getSwCorporationAccountList()

      mutations.setList(data.list)
      mutations.setIsFresh(true)
    } catch (e) {
      console.error(e)

      mutations.setList([])
      mutations.setIsFresh(false)

      throw e
    }
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const swCorporationAccount = { getters, actions }
