import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { SwUserProfileView } from "~/clApi/viewModel/swUserProfile"

const basePath = "/user_profile/user/sw_user_profile"

export const read = {
  getSwUserProfile: ({
    projectId,
    chatGroupId,
  }: {
    projectId?: string
    chatGroupId?: string
  }) => {
    const queryParams = projectId
      ? `?projectId=${projectId}`
      : chatGroupId
      ? `?chatGroupId=${chatGroupId}`
      : ""

    return new ApiClient<SwUserProfileView>().get(
      `${basePath}/me${queryParams}`
    )
  },
}
