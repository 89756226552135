// SW連携のリクエストパラメータは一部スネークケースであるため、ESLintのcamelcaseルールを無効化しています。
/* eslint-disable camelcase */
/**
 * SW連携用のAPIクライアント
 */
import { LinksProfile, SwUserProfileView } from "../viewModel/swUserProfile"
const baseURL = process.env.SW_BASE_URL

class ApiClient {
  static readonly APPLICATION_CUSTOM_HEADER: object = {
    "Content-Type": "application/x-www-form-urlencoded",
  }

  // SW連携の仕様を満たすために、axiosやfetchを使用せずにfromタグでのsubmitを行う
  public postByForm(path: string, params: RequestParams): void {
    // 開発環境以外で実行
    if (process.env.NODE_ENV === "development") {
      return
    }

    const form = document.createElement("form")
    form.method = "post"
    form.action = encodeURI(baseURL + path)

    const createHiddenInput = (name: string, value: string) => {
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = name
      input.value = value
      return input
    }

    // SWに連携するデータをフォームに追加
    form.appendChild(createHiddenInput("hash", params.hash))
    form.appendChild(
      createHiddenInput("timestamp", params.timestamp.toString())
    )
    form.appendChild(createHiddenInput("project_id", params.project_id))
    form.appendChild(createHiddenInput("email", params.email))
    form.appendChild(
      createHiddenInput("links_profile", JSON.stringify(params.links_profile))
    )

    document.body.appendChild(form)
    // TODO 本番環境ではfetchを使用してリクエストを送信するとCORSエラーが発生するためコメントアウト(原因分かり次第対応)
    // // 事前にSW側のサーバーが正常に動作しているか確認するため、fetchでリクエストを送信する
    // const response = await fetch(encodeURI(baseURL + path), {
    //   // SW側でOPTIONSかPOSTして許可されていないため、OPTIONSを使用
    //   method: "OPTIONS",
    // })
    // if (!response.ok) {
    //   throw new Error(response.statusText)
    // }
    // 確実にformのsubmit後にform要素を削除するためのイベントリスナーを追加する
    form.addEventListener("submit", () => {
      setTimeout(() => {
        document.body.removeChild(form)
      }, 0)
    })
    form.submit()
  }
}

type RequestParams = {
  hash: string
  timestamp: number
  // リンクスのプロジェクトIDではなく、SWの案件ID
  project_id: string
  email: string
  links_profile: LinksProfile
}

export const swApi = {
  createSwAccount: (params: SwUserProfileView) => {
    const requestParams: RequestParams = {
      hash: params.hash,
      timestamp: params.timestamp,
      project_id: params.swProjectId,
      email: params.email,
      links_profile: params.linksProfile,
    }
    return new ApiClient().postByForm(`/signup/crowdlinks`, requestParams)
  },
}
