

































import Vue, { PropType } from "vue"
import MaterialIcon, {
  OUTLINED,
} from "~/components/guideline/icon/MaterialIcon.vue"

type ColorType = "primary" | "key" | "sub" | "plain" | "attention" | "white"
type ButtonSize = "tiny" | "small" | "middle" | "large"

export default Vue.extend({
  components: {
    MaterialIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<"button" | "submit">,
      default: "button",
    },
    color: {
      type: String as PropType<ColorType>,
      default: "primary",
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: "middle",
    },
    iconName: {
      type: String,
      default: null,
    },
    iconType: {
      type: String,
      default: OUTLINED,
    },
    isInlineBlock: {
      type: Boolean,
      default: false,
    },
    hasDropShadow: {
      type: Boolean,
      default: false,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isTextWrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    additionalClassName(): string {
      const classes = [`is-${this.color}`, `is-${this.size}`]

      if (this.hasDropShadow) classes.push("has-drop-shadow")
      if (this.isBold) classes.push("is-bold")
      if (this.isFullWidth) classes.push("is-pc-full-width")
      if (this.isInlineBlock) classes.push("is-inline-block")
      if (this.disabled || this.loading) {
        classes.push("is-disabled")
      }

      return classes.join(" ")
    },
  },
  methods: {
    onClick(e: any) {
      this.$emit("click", e)
    },
  },
})
