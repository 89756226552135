import { main } from "./main"
import { publicOGP } from "./publicOGP"
import { swApi } from "./swApi"

export const clApi = {
  main,
  publicOGP,
  swApi,
}

export interface CLApiSchema {
  main: typeof main
  publicOGP: typeof publicOGP
  swApi: typeof swApi
}
