import { ApiClient } from "~/clApi/main/foundation/ApiClient"

const basePath = "/matching/user/sw_project_application"

export const write = {
  applyForSwProject: (params: { projectId: string }) =>
    new ApiClient().post(`${basePath}/create`, {
      projectId: params.projectId,
    }),
}
